<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          TÀI LIỆU ĐÍNH KÈM
        </b-badge>
        <div class="card-header-action">
          <!--          <feather-icon-->
          <!--            v-if="hasImgTicked"-->
          <!--            v-b-tooltip.hover="'Sao chép ảnh đã chọn'"-->
          <!--            icon="CopyIcon"-->
          <!--            size="24"-->
          <!--            class="text-primary cursor-pointer"-->
          <!--            @click="copyImgTick"-->
          <!--          />-->
          <feather-icon
            v-if="hasImgTicked"
            v-b-tooltip.hover="'Tải về ảnh đã chọn'"
            icon="DownloadIcon"
            size="24"
            class="text-primary cursor-pointer ml-1"
            @click="downloadImgTick"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div v-if="imgFiles.length">
          <h5 class="text-primary mb-1">
            <b-badge
              variant="light-primary"
              size="sm"
            >
              Hình ảnh
            </b-badge>
            (<strong>{{ imgFiles.length }}</strong>)
          </h5>
          <viewer
            :images="imgFiles"
            class="viewer-wrap mb-2"
          >
            <div
              v-for="(img, idx) in imgFiles"
              :key="idx"
              class="viewer-item position-relative"
              :class="{'featured-image': value.image === img.origin}"
            >
              <img
                :src="getUrlFile(img.origin, value.updated_at)"
                class="h-100 w-100"
                :alt="img.filename || 'Ảnh chi tiết'"
              >
              <div class="viewer-item-icon">
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="24"
                  @click="openLinkInNewTab(getUrlFile(img.origin, value.updated_at))"
                />
                <feather-icon
                  icon="DownloadIcon"
                  size="24"
                  @click="downloadImg(img.origin, img.origin)"
                />
                <div class="flex-grow-1 d-flex justify-content-end">
                  <b-form-checkbox
                    :key="'tick-img-to-action-' + img.origin"
                    v-model="imgTick[img.origin]"
                    class="tick-image"
                  />
                </div>
              </div>
            </div>
          </viewer>
          <hr>
          <div
            v-if="pdfFiles.length"
            class="list-file-pdf"
          >
            <h5 class="text-primary mb-1">
              <b-badge
                variant="light-primary"
                size="sm"
              >
                Pdf
              </b-badge>
              (<strong>{{ pdfFiles.length }}</strong>)
            </h5>
            <div
              v-for="(file, idx) in pdfFiles"
              :key="idx"
              class="viewer-item position-relative text-primary cursor-pointer d-flex mb-1"
            >
              <div
                @click="showPreviewFile(file, 'pdf')"
              >

                <b-img
                  src="@/assets/images/icons/icon-pdf.png"
                  alt="icon pdf"
                  class="mr-1"
                  width="20px"
                />
                {{ file.origin }}
                <feather-icon
                  icon="EyeIcon"
                  class="ml-1"
                  size="20"
                />
              </div>
              <feather-icon
                icon="DownloadIcon"
                class="ml-1"
                size="20"
                @click="downloadImg(file.origin, file.origin)"
              />
            </div>
            <hr>
          </div>
        </div>
        <div v-else>
          Chưa có tài liệu đính kèm
        </div>
      </b-card-body>
    </b-card>

    <div
      v-if="isShowPdfPreview && currentFilePdfUrl"
    >
      <div
        class="preview-file file-pdf"
      >
        <div
          v-show="pdfProgress < 100"
          class="pdf-loading"
        >
          <div class="w-100 h-100 d-flex flex-column justify-content-center">
            <label>{{ pdfProgress === 0 ? 'Đang tải dữ liệu...' : 'Đang chuyển đổi dữ liệu...' }}</label>
            <b-progress
              max="100"
            >
              <b-progress-bar :value="pdfProgress">
                <strong>{{ pdfProgress }} %</strong>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>

        <div style="z-index: 9999">
          <feather-icon
            class="icon-close text-danger"
            icon="XIcon"
            size="48"
            @click="isShowPdfPreview=false"
          />
          <pdf
            v-for="i in numPagesCurrentPdf"
            :key="i"
            :src="currentFilePdfUrl"
            class="mb-1"
            :page-number="i"
            :page="i"
            @loaded="pdfLoaded = true"
            @progress="p => handlePdfProgress(p, i)"
          />
        </div>
      </div>
    </div>

    <!--    <div-->
    <!--      v-if="isShowDocPreview && fileDocUrl"-->
    <!--      class="preview-file file-doc"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        class="icon-close text-danger"-->
    <!--        icon="XIcon"-->
    <!--        size="48"-->
    <!--        @click="isShowDocPreview=false"-->
    <!--      />-->
    <!--      <VueDocPreview-->
    <!--        :value="fileDocUrl"-->
    <!--        type="office"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import pdf from 'vue-pdf'
// import VueDocPreview from 'vue-doc-preview'
import {
  BBadge, BCard, BCardBody, BCardHeader, BFormCheckbox, BImg, BProgress, BProgressBar, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardHeader,
    BBadge,
    BCardBody,
    BImg,
    BProgress,
    BProgressBar,
    // VueDocPreview,
    pdf,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
    tickToActions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    copyImgTick() {
      const imgs = Object.keys(this.imgTick).filter(key => this.imgTick[key]) || []
      imgs.forEach(img => {
        console.log(img)
      })
    },
    downloadImgTick() {
      const imgs = Object.keys(this.imgTick).filter(key => this.imgTick[key]) || []
      imgs.forEach(name => this.downloadImg(name, name))
    },
    handlePdfProgress(percent, idx) {
      this.pdfProgressPerPage[idx] = percent

      let totalPercent = 0
      Object.keys(this.pdfProgressPerPage)
        .forEach(key => {
          totalPercent += this.pdfProgressPerPage[key]
        })
      this.pdfProgress = Math.floor((totalPercent * 100) / this.numPagesCurrentPdf)
    },
    showPreviewFile(file, type = 'pdf') {
      if (type === 'pdf') {
        this.currentFilePdfUrl = `${$themeConfig.app.host}/file/${file.origin}`
        this.isShowPdfPreview = true
      }
      // if (type === 'doc') {
      //   this.fileDocUrl = file.origin
      //   this.isShowDocPreview = true
      // }
    },
  },
  setup(props) {
    const toast = useToast()

    const IMAGE_TYPE = 'png gif jpg jpeg jfif pjpeg pjp svg webp apng'
    const PDF_TYPE = 'pdf'
    // const DOC_TYPE = 'doc docx'

    const pdfLoaded = ref(false)
    const pdfProgressPerPage = ref({})
    const pdfProgress = ref(0)
    const numPagesCurrentPdf = ref(null)
    const currentFilePdfUrl = ref('')
    const loadingCurrentPdfTask = ref(null)
    const isShowPdfPreview = ref(false)
    const imgTick = ref({})

    // const fileDocUrl = ref('')
    // const isShowDocPreview = ref(false)

    const imgFiles = computed(() => {
      const files = props.value.document_files || []
      const imgs = files.filter(f => IMAGE_TYPE.split(' ')
        .includes(f.origin.split('.').pop()))
      return imgs || []
    })

    const pdfFiles = computed(() => {
      const files = props.value.document_files || []
      const pdfs = files.filter(f => PDF_TYPE.split(' ')
        .includes(f.origin.split('.').pop()))
      return pdfs || []
    })

    const hasImgTicked = computed(() => Object.values(imgTick.value).filter(item => item).length)

    // const docFiles = computed(() => {
    //   const files = props.value.document_files || []
    //   const docs = files.filter(f => DOC_TYPE.split(' ')
    //     .includes(f.origin.split('.').pop()))
    //   return docs || []
    // })

    watch([currentFilePdfUrl], () => {
      pdfProgressPerPage.value = {}
      pdfLoaded.value = false
      loadingCurrentPdfTask.value = pdf.createLoadingTask(currentFilePdfUrl.value)
      loadingCurrentPdfTask.value.promise.then(p => {
        numPagesCurrentPdf.value = p.numPages
      }).catch(e => {
        isShowPdfPreview.value = false
        toast({
          component: ToastificationContent,
          props: { title: e.message, variant: 'danger' },
        })
      })
    })

    return {
      imgFiles,
      pdfLoaded,
      pdfProgressPerPage,
      pdfProgress,
      isShowPdfPreview,
      pdfFiles,
      numPagesCurrentPdf,
      loadingCurrentPdfTask,
      currentFilePdfUrl,
      imgTick,
      hasImgTicked,
      // fileDocUrl,
      // isShowDocPreview,
      // docFiles,
    }
  },
}
</script>

<style lang="scss">

.viewer-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(100% / 2 - .5rem));
  grid-template-rows: 150px;
  gap: .5rem;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, calc(100% / 3 - .5rem));
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, calc(100% / 4 - .5rem));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, calc(100% / 5 - .5rem));
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fit, 200px);
  }

  .viewer-item {
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 0 10px 0 rgb(34 41 47 / 20%);
    transition-duration: .3s;
    cursor: pointer;

    > img {
      object-fit: contain;
    }

    &:hover {
      box-shadow: 0 4px 24px 0 rgb(34 41 47);

      .viewer-item-icon {
        background-color: rgba(115, 103, 240, .9) !important;
        color: white !important;
      }
    }

    .viewer-item-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: .5rem;
      transition-duration: .3s;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      align-items: center;

      > *:not(last-child) {
        margin-right: .5rem;
      }

      img {
        object-fit: cover;
      }
    }
  }
}

.preview-file {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5% 10%;
  left: 0;
  top: 0;
  z-index: 9999;

  .icon-close {
    background-color: ghostwhite;
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    transition-duration: .3s;
    cursor: pointer;
    padding: .5rem;
    border-radius: 1rem;
    border: 2px solid white;
    position: fixed;
    top: 5%;
    right: 10%;
    transform: translate(50%, -50%);
    z-index: 9999;

    &:hover {
      box-shadow: 0 0 10px 0 black;
    }
  }
}

[page-number] {
  position: relative;

  &:before {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.pdf-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9998;
  background: white;
  min-width: 30%;
  padding: 2rem 1rem;
  border-radius: 1rem;
}

.dc-top {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.viewer-item.featured-image {
  position: relative;
  border-radius: 1rem;
  border: 3px solid #51bcda;
  overflow: hidden;
  box-shadow: 0 0 10px 0 #51bcda;
}
</style>
