import store from '@/store'
import constant from '@/global/const'
import util from '@/global/util'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import http from '@/global/http'

export default function useProduct() {
  const { parseFieldsToNumber } = util

  const routeName = computed(() => store.state.app.currentRouteName)
  const productMenuType = computed(() => {
    if (routeName.value.includes('check-product')) return ''
    if (routeName.value.includes('hired')) return 'hired'
    if (routeName.value.includes('sell')) return 'sell'
    if (routeName.value.includes('hotel')) return 'hotel'
    return 'sell'
  })
  const productMenuTypeVI = computed(() => {
    if (routeName.value.includes('check-product')) return ''
    return (routeName.value.includes('hired') ? 'cho-thue' : 'ban')
  })
  const productListMenuType = computed(() => {
    if (routeName.value.includes('check-product')) return ''
    return (routeName.value.includes('manage') ? 'manage' : 'list')
  })

  const formatClientData = (clientData = {}) => {
    // những trường này sẽ được chuyển thành kiểu number
    const NumberField = ['ward_id', 'district_id', 'province_id',
      'street_id', 'price', 'input_price', 'transfer_price',
      'width', 'length', 'acreage', 'floor_number', 'corner_number',
      'room_number_total', 'rating_stars', 'basement_number', 'elevator_number',
      'deposit_time', 'pay_time', 'lease_term', 'decor_time']

    return parseFieldsToNumber(clientData, NumberField)
  }

  const renderProductStructure = (data, withWidthLength = true) => [
    (withWidthLength && data.width && data.length) ? (`${data.width}x${data.length}m, `) : '',
    data.basement ? '1 hầm, ' : '',
    '1 trệt, ',
    data.badger ? '1 lửng, ' : '',
    data.floor_number ? `${data.floor_number} lầu, ` : '',
    data.elevator ? 'thang máy, ' : '',
    data.terrace ? 'ST' : '',
  ].join('')

  const getAddressInfo = p => ({
    provinceInfo: p.province ? { ...p.province, text: p.province.name } || store.state.app.provinces.find(item => item.id === p.province_id) : {},
    districtInfo: p.district || store.state.app.districts.find(item => item.id === p.district_id) || {},
    wardInfo: p.ward || store.state.app.wards.find(item => item.id === p.ward_id) || {},
    streetInfo: p.street || store.state.app.streets.find(item => item.id === p.street_id) || store.state.app.streetsByDistricts.find(item => item.id === p.street_id) || {},
  })

  const renderProductAddress = p => {
    const {
      provinceInfo, districtInfo, wardInfo, streetInfo,
    } = getAddressInfo(p)

    return [
      `${p.house_number ? ` số ${p.house_number}` : ''}`,
      `${streetInfo.name ? ` đường ${streetInfo.name}` : ''}`,
      `${wardInfo.name ? `, ${wardInfo.pre.toLowerCase()} ${wardInfo.name}` : ''}`,
      `${districtInfo.name ? `, ${districtInfo.pre} ${districtInfo.name}` : ''}`,
      `${provinceInfo.text ? `, ${provinceInfo.text}` : ''}`,
    ].join('')
  }

  const renderProductTitle = (p, renderPrice = true) => {
    const {
      provinceInfo, districtInfo, wardInfo, streetInfo,
    } = getAddressInfo(p)
    const typeInfo = constant.TYPE_PRODUCT.find(item => item.value === p.type)
    const arr = [
      `${typeInfo ? typeInfo.text.toLowerCase() : ''}`,
      `${p.has_corner ? ' GÓC 2MT' : ''}${p.house_number ? ` số ${p.house_number}` : ''}`,
      `${streetInfo.name ? ` đường ${streetInfo.name}` : ''}`,
      `${wardInfo.name ? `, ${wardInfo.pre.toLowerCase()} ${wardInfo.name}` : ''}`,
      `${districtInfo.name ? `, ${districtInfo.pre} ${districtInfo.name}` : ''}`,
      `${provinceInfo.text ? `, ${provinceInfo.text}` : ''}`,
    ]

    switch (p.formality) {
      case 'cho-thue':
        arr.unshift('Cho thuê ')
        if (renderPrice) {
          arr.push(`. Giá: ${p.priceDisplay}/tháng`)
        }
        break
      case 'ban':
        if (p.type === 'ban-dat') {
          arr.unshift('Bán đất ')
          if (renderPrice) {
            arr.push(`. Giá: ${p.priceDisplay}`)
          }
        } else {
          arr.unshift('Bán ')
          if (renderPrice) {
            arr.push(`. Giá: ${p.priceDisplay}`)
          }
        }
        break
      case 'sang-nhuong':
        arr.unshift('Sang nhượng ')
        if (renderPrice) {
          arr.push(`. Giá: ${p.priceDisplay}`)
        }
        break
      default:
        break
    }
    return arr.join('')
  }

  const renderProductContent = (p, withContact = true) => {
    const userData = store.app?.userData || {}
    const { hotline, email } = $themeConfig.app
    const websiteName = $themeConfig.app.appName
    const expandStyleInfo = constant.EXPAND_STYLE.find(item => item.value === p.expand_style) || {}
    let content = []

    switch (p.formality) {
      case 'cho-thue':
        content = [
          `${renderProductTitle(p, false)}`,
          `- Diện tích: ${p.width && p.length ? `${p.width}x${p.length}m` : '...'}${expandStyleInfo.value ? (` ${expandStyleInfo.text} ${p.expand_style_info ? ` ${p.expand_style_info}` : ''}m`) : ''}`,
          `- Kết cấu: ${renderProductStructure(p, false)}`,
          `- Giá cho thuê: ${p.input_price ? `${util.formatNumber(p.input_price)} ${p.currency ? constant.CURRENCY.find(item => item.value === p.currency).text : ''}/tháng` : '...'}`,
          '- Thời gian bàn giao: ...',
          'Mô tả chi tiết về nhà cho thuê: ...',
          withContact ? '&nbsp&nbsp&nbsp' : '',
          withContact ? 'Liên hệ:' : '',
          withContact ? `${websiteName}` : '',
          withContact ? `${userData.name || ''}` : '',
          withContact ? `${userData.phone ? `Phone: ${userData.phone}` : `Hotline ${hotline}`}` : '',
          withContact ? `${userData.email ? `Email: ${userData.email}` : `Email: ${email}`}` : '',
        ].filter(item => item).join('<br/>')

        break
      case 'ban':
        content = [
          `${renderProductTitle(p, false)}`,
          `- Diện tích: ${p.width && p.length ? `${p.width}x${p.length}m` : '...'}${expandStyleInfo.value ? (` ${expandStyleInfo.text} ${p.expand_style_info ? ` ${p.expand_style_info}` : ''}m`) : ''}`,
          `- Kết cấu: ${renderProductStructure(p, false)}`,
          `- Giá bán: ${p.input_price ? (`${util.formatNumber(p.input_price)} ${p.currency ? constant.CURRENCY.find(item => item.value === p.currency).text : ''}`) : '...'}`,
          '- Pháp lý: ...',
          'Mô tả chi tiết về nhà bán: ...',
          withContact ? '&nbsp&nbsp&nbsp' : '',
          withContact ? 'Liên hệ:' : '',
          withContact ? `${websiteName}` : '',
          withContact ? `${userData.name || ''}` : '',
          withContact ? `${userData.phone ? `Phone: ${userData.phone}` : `Hotline ${hotline}`}` : '',
          withContact ? `${userData.email ? `Email: ${userData.email}` : `Email: ${email}`}` : '',
        ].filter(item => item).join('<br/>')

        break
      case 'sang-nhuong':
        content = [
          `${renderProductTitle(p, false)}`,
          `- Diện tích: ${p.width && p.length ? `${p.width}x${p.length}m` : '...'}${expandStyleInfo.value ? (`${expandStyleInfo.text + expandStyleInfo.value}m`) : ''}`,
          `- Kết cấu: ${renderProductStructure(p, false)}`,
          `- Giá cho thuê: ${p.input_price ? `${util.formatNumber(p.input_price)} ${constant.CURRENCY.find(item => item.value === p.currency).text}/tháng` : '...'}`,
          `- Giá sang nhượng: ${p.transfer_price ? (`${util.formatNumber(p.transfer_price)} ${p.transfer_price_currency ? constant.CURRENCY.find(item => item.value === p.transfer_price_currency).text : ''}`) : '...'}`,
          `- Giá sang nhượng: ${p.transferPriceDisplay || '...'}`,
          'Mô tả chi tiết về nhà sang nhượng: ...',
          withContact ? '&nbsp&nbsp&nbsp' : '',
          withContact ? 'Liên hệ:' : '',
          withContact ? `${websiteName}` : '',
          withContact ? `${userData.name || ''}` : '',
          withContact ? `${userData.phone ? `Phone: ${userData.phone}` : `Hotline ${hotline}`}` : '',
          withContact ? `${userData.email ? `Email: ${userData.email}` : `Email: ${email}`}` : '',
        ].filter(item => item).join('<br/>')
        break
      default:
        break
    }
    return content
  }

  const renderProductOfficeStructure = (data, withWidthLength = true, withDirection = true, lang = 'vi') => [
    (withWidthLength && data.width && data.length) ? (`${data.width}x${data.length}m, `) : '',
    (withDirection && data.direction) ? (`hướng ${(constant.OFFICE_DIRECTION.find(d => d.value === data.direction) || {}).text}, ` || '') : '',
    data.basement_number ? `${data.basement_number} ${lang === 'vi' ? 'hầm' : 'basement(s)'}, ` : '',
    data.floor_number ? `${data.floor_number} ${lang === 'vi' ? 'lầu' : 'floor(s)'}, ` : '',
    data.elevator_number ? `${data.elevator_number} ${lang === 'vi' ? 'thang máy' : 'elevator(s)'}` : '',
  ].join('')

  const renderProductOfficeTitle = (p, showMore = true) => {
    const {
      provinceInfo, districtInfo, wardInfo, streetInfo,
    } = getAddressInfo(p)

    const title = [
      `${p.is_rent_all_apartment ? '[NGUYÊN CĂN] Cho thuê' : ''} ${p.name} `,
      'ở ',
      `${p.house_number ? `số ${p.house_number} ` : ''}`,
      streetInfo.name ? (`đường ${streetInfo.name}, `) : '',
      `${wardInfo.name ? (`${wardInfo?.pre.toLowerCase()} ${wardInfo.name}`) : ''}, `,
      `${districtInfo.name ? districtInfo.name : ''}, `,
      `${provinceInfo.text ? provinceInfo.text : ''}`,
      showMore ? `, có diện tích sàn: ${p.floor_area}, ` : '',
      showMore ? `giá trọn gói: ${util.formatNumber(p.price)} USD/m2` : '',
    ].join('')
    return title
  }

  const renderProductOfficeContent = data => {
    const renderContent = [
      `${renderProductOfficeTitle(data, false)}`,
      // `- Sàn điển hình: ${data.area_description}`,
      `- Diện tích sàn: ${data.floor_area}`,
      '- Mô tả diện tích:',
      ...data.rental_area_description.split('\n').map(row => `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${row}`),
      `- Kết cấu: ${renderProductOfficeStructure(data, false)}`,
      `- Giá thuê trọn gói: ${data.input_price ? `${util.formatNumber(data.price)} USD/m2 (${data.price_description})` : '...'}`,
      '',
      'Thông tin khác:',
      `- Phí ô-tô: ${util.formatNumber(data.car_parking_fee)} VNĐ/Xe/Tháng`,
      `- Phí mô-tô: ${util.formatNumber(data.moto_parking_fee)} VNĐ/Xe/Tháng`,
      `- Phí ngoài giờ: ${util.formatNumber(data.overtime_fee)} ${(constant.OVERTIME_FEE_TYPE.find(oft => oft.value === data.overtime_fee_type) || {}).text || ''}`,
      `- Tiền điện: ${util.formatNumber(data.electricity_fee)} ${(constant.ELECTRICITY_FEE_TYPE.find(oft => oft.value === data.electricity_fee_type) || {}).text || ''}`,
      `- Đặt cọc: ${data.deposit_time} tháng`,
      `- Thanh toán: ${data.pay_time} tháng`,
      `- Thời hạn thuê: ${data.lease_term} năm`,
      `- Trang trí: ${data.decor_time} ngày`,
    ]
    return renderContent.join('<br/>')
  }
  // api
  const checkProductUnique = (data, cb) => http.handle(store.dispatch('product/checkProductUnique', data), cb)
  const createProduct = (data, cb) => http.handle(store.dispatch('product/createProduct', formatClientData(data)), cb)
  const fetchProduct = (id, cb) => http.handle(store.dispatch('product/fetchProduct', { id }), cb)
  const updateProduct = (data, cb) => http.handle(store.dispatch('product/updateProduct', formatClientData(data)), cb)
  const closeDealProduct = (data, cb) => http.handle(store.dispatch('product/closeDealProduct', data), cb)
  const openDealProduct = (id, cb) => http.handle(store.dispatch('product/openDealProduct', id), cb)
  const refreshProduct = (id, cb) => http.handle(store.dispatch('product/refreshProduct', id), cb)
  const deleteProduct = (id, cb) => http.handle(store.dispatch('product/deleteProduct', id), cb)
  const fetchSuggestedListCustomer = (data, cb) => http.handle(store.dispatch('product/fetchSuggestedListCustomer', data), cb)
  const exportExcel = (q, cb) => http.handle(store.dispatch('product/exportExcel', q), cb)

  return {
    productMenuType,
    productMenuTypeVI,
    productListMenuType,
    formatClientData,
    renderProductAddress,
    renderProductStructure,
    renderProductTitle,
    renderProductContent,
    renderProductOfficeStructure,
    renderProductOfficeTitle,
    renderProductOfficeContent,
    //
    checkProductUnique,
    createProduct,
    fetchProduct,
    updateProduct,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
    exportExcel,
    deleteProduct,
    fetchSuggestedListCustomer,
  }
}
