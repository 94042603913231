import { ref, reactive } from '@vue/composition-api'

const ProductModal = () => reactive({
  id: ref(''),
  user_id: ref(null),
  title: ref(''),
  source_id: ref(''),
  image: ref(''),
  content: ref(''),
  province_id: ref(null),
  district_id: ref(null),
  ward_id: ref(null),
  street_id: ref(null),
  formality: ref(''),
  type: ref(''),
  house_number: ref(''),
  expand_style: ref(''),
  currency: ref(''),
  type_of_payment: ref(''),
  expand_style_info: ref(''),
  expire_contract_date: ref(''),
  input_price: ref(null),
  price: ref(null),
  class: ref(null),
  direction: ref(null),
  rental_area: ref([]),
  priceDisplay: ref(null),
  transfer_price: ref(null),
  width: ref(null),
  length: ref(null),
  acreage: ref(null),
  floor_area: ref(null),
  floor_number: ref(null),
  is_can_edit: ref(null),
  corner_number: ref(null),
  badger: ref(null),
  elevator: ref(null),
  basement: ref(null),
  is_hot: ref(null),
  has_corner: ref(null),
  show_in_web: ref(null),
  commission: ref(''),
  view: ref(null),
  status: ref(''),
  host_name: ref(''),
  host_phone1: ref(''),
  host_phone2: ref(''),
  transfer_price_currency: ref(''),
  created_at: ref(''),
  updated_at: ref(''),
  terrace: ref(null),
  input_tl: ref(''),
  total_price: ref(null),
  product_type: ref(null),
  close_deal_type: ref(''),
  area_by_book: ref(null),
  source: ref(null),
  room_number_total: ref(null),
  rating_stars: ref(null),
  logs: ref([]),
  document_files: ref([]),
  collection_id: ref([]),
  user_name: ref(''), // người tạo
  obj_collection: ref([]),
  is_owner: ref(true),
  is_creator: ref(true),
  is_manager: ref(false),
  options: ref(''),
  price_description: ref(null),
  car_parking_fee: ref(null),
  moto_parking_fee: ref(null),
  overtime_fee: ref(null),
  overtime_fee_type: ref(null),
  electricity_fee: ref(null),
  electricity_fee_type: ref(null),
  deposit_time: ref(null),
  pay_time: ref(null),
  lease_term: ref(null),
  name: ref(null),
  decor_time: ref(null),
  service_fee: ref(null),
  is_rent_all_apartment: ref(null),
  basement_number: ref(0),
  elevator_number: ref(0),
  rental_area_description: ref(''),
  is_hidden_phone: ref(false),
  area_description: ref(''),
  contact: ref(null),
  contact_id: ref(null),
})

export default ProductModal
